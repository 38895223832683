<template>
  <div class="productEngine">
    <div class="play-root">
      <video src="../../assets/video/黑洞.mp4" muted autoplay loop id="dplayer"></video>
      <div class="title">
        <h1>聚合为一，承载万物</h1>
        <p>近乎无限承载力的BIM+GIS图形平台</p>
        <!-- <span>开发者中心</span> -->
        <span><a target="_blank" href="https://developer.bjblackhole.com/">开发者中心</a></span>
        <!-- <span @click="to('downloadcenter')">下载</span> -->
      </div>
    </div>
    <div class="blackhole">
      <div class="container">
        <h4>“黑洞”三维图形引擎</h4>
        <p class="alignLeft">“黑洞”引擎是秉匠科技联合长安大学共同研发完成，完全国产自主知识产权。“黑洞”引擎具有海量数据承载及高逼真渲染能力，主要为土木工程参建各方提供基于Web端的多源异构BIM模型可视化和调度分析能力，解决设计、建造、运维过程中的可视化沟通及分析等问题，还可为城市CIM应用提供数字化底座，支撑CIM平台的高效运行。</p>
        <ul>
          <li>
            <div style="width:100%;height:52px;display:flex;align-items: center;justify-content: center;">
              <img style="width:50px" src="../../assets/image/product/balckhole/效果.png" alt=""/>
            </div>

            <h4>几可乱真的渲染效果</h4>
            <p>1、光源强度和阴影质量可根据实际需要自由配置。</p>
            <p>2、动态计算的实时阴影和丰富的阴影层次，无需预烘焙即可得到逼真的阴影效果。</p>
          </li>
          <li>
            <img src="../../assets/image/product/balckhole/地形.png" alt="">
            <h4>多细节融合地形</h4>
            <p>1、遥感高程、倾斜摄影等数据之间灵活、精准匹配。</p>
            <p>2、多精度数据支持和高效动态调度机制满足精度和速度之间的完美平衡。</p>
            <p>3、内置支持海量材质混合的地形网格系统，为表达地形上的不同业务信息提供了无限可能。</p>
          </li>
          <li>
            <img src="../../assets/image/product/balckhole/河湖水面率.png" alt="">
            <h4>参数驱动的高仿真水面系统</h4>
            <p>1、照片级别的水面光学效果特性模拟。</p>
            <p>2、动态的、参数驱动的水面波动效果，支持风向、流向等各种参数的设置。</p>
            <p>3、支持多高度多水面系统，灵活的水面形态指定方案。</p>
          </li>
          <li>
            <img src="../../assets/image/product/balckhole/植被.png" alt="">
            <h4>多层次高仿真植被系统</h4>
            <p>1、支持海量、上百类树种的同屏呈现。</p>
            <p>2、支持高精度、动态树木呈现，可真实模拟树木质感和枝叶摆动。</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="more">
      <div class="container">
        <div class="img">
          <img src="../../assets/image/nebula/more.png" alt="">
          <div class="play center" @click="play"><i class="el-icon-video-play"></i></div>
        </div>
        <p>优异的性能给您最流畅的视觉体验</p>
      </div>
    </div>
    <div class="data container">
      <p>只要存储空间够大，可实现几乎无上限的承载能力</p>
      <div class="mian">
        <ul>
          <li><img class="nomove" src="../../assets/image/nebula/组合图/1.png" alt=""></li>
          <li><img class="pic_bottom" src="../../assets/image/nebula/组合图/2.png" alt=""></li>
          <li><img class="nomove" src="../../assets/image/nebula/组合图/4.png" alt=""></li>
          <li><img class="pic_bottom" src="../../assets/image/nebula/组合图/2.png" alt=""></li>
          <li><img class="nomove" src="../../assets/image/nebula/组合图/6.png" alt=""></li>
          <li><img class="pic_top" src="../../assets/image/nebula/组合图/2.png" alt=""></li>
          <li><img class="nomove" src="../../assets/image/nebula/组合图/3.png" alt=""></li>
          <li><img class="pic_top" src="../../assets/image/nebula/组合图/2.png" alt=""></li>
          <li><img class="nomove" src="../../assets/image/nebula/组合图/5.png" alt=""></li>
          <li><img class="pic_top" src="../../assets/image/nebula/组合图/2.png" alt=""></li>
        </ul>
      </div>
      <p>目前遇到的最大的数据</p>
    </div>
    <div class="intruduce">
      <div class="container">
        <ul>
          <li>
            <img src="../../assets/image/product/balckhole/承载超限(2).png" alt="">
            <h4>几乎无限的模型承载力</h4>
            <p class="alignLeft">引擎利用拓扑结构可变的网格简化方法，为整个场景创建连续的分层次细节模型，在不影响模型渲染效率的同时，可达到近乎无上限的模型承载力。</p>
          </li>
          <li>
            <img src="../../assets/image/product/balckhole/精确.png" alt="">
            <h4>不损失原有模型精度的数据处理</h4>
            <p class="alignLeft">基于“可逆”的轻量化理念，实现模型到BlackHole Engine的高精度、全信息、全自动无损传递。</p>
          </li>
          <li>
            <img src="../../assets/image/product/balckhole/瓦片化(1).png" alt="">
            <h4>基于空间块的瓦片化技术</h4>
            <p class="alignLeft">空间层次化LOD技术+二次型误差算法+磁盘分页交换技术，对超大模型进行瓦片化处理。自动适配硬件配置，根据不同屏幕空间距离进行不同层级的实时渲染调度。以空间块为基本单位的遮挡剔除，极速高效渲染。</p>
          </li>
          <li>
            <img src="../../assets/image/product/balckhole/跨平台(1).png" alt="">
            <h4>跨平台支持</h4>
            <p class="alignLeft">以OpenGL3.0、OpenGL ES3.0、WebGL2.0作为底层支撑，基于 标准C++自主研发的引擎内核，建立了一套高性能、支持多种CPU架构和操作系统的原生跨平台技术体系。</p>
          </li>
        </ul>
      </div>
    </div>
    <!-- 遮罩层 -->
    <div id="mask" ref="mask">
      <i class="el-icon-close" @click="closeplay"></i>
      <div class="pink"><video src="../../assets/video/黑洞介绍.mp4" muted autoplay loop controls id="dplayer"></video></div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    to (path) {
      this.$router.push(path)
    },
    play () {
      this.$refs.mask.style.display = 'block'
      this.$refs.mask.style.height = document.getElementById('app').clientHeight + 'px'
    },
    closeplay () {
      this.$refs.mask.style.display = 'none'
    }
  }
}
</script>

<style lang="less" scoped>
*{
  box-sizing: border-box;
}
.alignLeft {
  text-align: left;
}
#mask{
  width: 100%;
  // opacity: 0.6;
  background-color: black;
  // background: rgba(0,0,0,1);
  bottom: 0;
  left: 0;
  position: fixed;
  z-index: 1001;
  display: none;
  i{
    position: absolute;
    top: 20px;
    right: 20px;
    color: #fff;
    font-size: 30px;
    z-index: 999;
    cursor: pointer;
  }
  .pink{
    width: 80%;
    height: 80%;
    // margin: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    video{
      width: 100%;
      height:100%;
      object-fit:fill;
    }
  }
}
.pic_bottom{
  width: 70% !important;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.pic_top{
  width: 70% !important;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.productEngine{
  color: #fff;
  .bg{
    width: 100%;
    height: 70vh;
    background: url('../../assets/image/product/bg.png') center center no-repeat;
    background-size: cover;
    position: relative;
    color: #fff;
    .info{
      position: absolute;
      top: 50%;
      left: 80px;
      transform: translateY(-50%);
      h3{
        font-size: 40px;
        font-weight: normal;
      }
      span{
        background-color: #0aaff1;
        font-size: 30px;
        padding: 10px 20px;
        // display: block;
      }
      p{
        font-size: 30px;
        line-height: 50px;
        margin: 20px 0;
      }
    }
  }
  .play-root {
    width: 100%;
    height: 60vh;
    margin: 0 auto;
    position: relative;
    video{
      width: 100%;
      height:100%;
      object-fit:fill;
    }
    .title{
      position: absolute;
      top: 50%;
      left: 50px;
      transform: translateY(-50%);
      width: 35%;
      // background-color: pink;
      color: #fff;
      h1{
        font-size: 50px;
        font-weight: normal;
        line-height: 80px;
        font-family: "siyuan";
        @media screen and (max-width:768px) {
          font-size: 30px;
          line-height: 40px;
        }
      }
      span{
        width: 100px;
        height: 40px;
        background-color: #0aaff1;
        display: block;
        line-height: 40px;
        margin-top: 20px;
        text-align: center;
        cursor: pointer;
        float: left;
        margin-right: 10px;
        a{
          color: #fff;
        }
      }
    }
  }
  .blackhole{
    padding: 20px;
    color: #000;
    text-align: center;
    h4{
      font-size: 20px;
      margin: 10px 0;
    }
    ul{
      margin: 40px 0;
      overflow: hidden;
      li{
        width: calc(25% - 20px);
        float: left;
        margin-right: 10px;
        border: 1px solid #ccc;
        padding: 10px 5px;
        height: 300px;
        @media screen and (max-width:768px) {
          width: 80%;
          float: none;
          margin: 10px auto !important;
        }
        img{
          width: 50px;
          // margin: 0 auto;
          // display: block;
        }
        p{
          text-align: left;
        }
      }
      li:last-child{
        margin-right: 0;
      }
      li:hover{
        box-shadow: 0 6px 9px rgba(0, 0, 0, 0.2);
      }
    }
  }
  .more{
    background: url('../../assets/image/product/bg_engine.jpg') center center no-repeat;
    padding: 50px 0;
    text-align: center;
    font-size: 16px;
    .img{
      position: relative;
      .play{
        i{
          font-size: 70px;
          color: #dadad9;
        }
        i:hover{
          color: #fff;
          cursor: pointer;
        }
      }
    }
    p{
      margin-bottom: 30px;
    }
  }
  .data{
    color: #215E9B;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    padding: 30px 0;
    .mian{
      ul{
        overflow: hidden;
        padding: 50px 150px;
        li{
          width: 20%;
          height: 200px;
          float: left;object-fit: scale-down;
          position: relative;
          // padding: 10px;
          img{
            width: 100%;
            object-fit: scale-down;
            cursor: pointer;
          }
          .nomove:hover{
           transform: scale(1.1);
           transition: all 0.6s;
          }
        }
        li:nth-child(even) {
          // width: 15%;
        }
      }
    }
  }
  .intruduce{
    background: url('../../assets/image/product/bg_engine02.jpg') center center;
    padding: 120px 0;
    // height: 50vh;
    ul{
      overflow: hidden;
      text-align: center;
      li{
        width: calc(25% - 20px);
        float: left;
        margin-right: 25px;
        @media screen and (max-width:768px) {
          width: 80%;
          float: none;
          margin: 0px auto 20px !important;
        }
        img{
          width: 50px;
        }
        h4{
          line-height: 40px;
        }
      }
      li:last-child{
        margin-right: 0;
      }
    }
  }

}
</style>
